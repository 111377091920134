"use client";

import { signIn, signOut, useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
} from "@web3modal/ethers/react";
import "~/styles/globals.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { refreshBalanceCountAtom, showLoaderOverlayAtom } from "~/state/atoms";
import { usePathname, useRouter } from "next/navigation";
import { api } from "~/trpc/react";
import { LoginType } from "@prisma/client";
import { Modal } from "antd";
import { env } from "~/env";

const clientId = env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID ?? "TEST_CLIENT_ID";

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x1", // Please use 0x1 for Mainnet
  rpcTarget:
    "https://eth-mainnet.g.alchemy.com/v2/dA5r-OdlIb1MPs9yRWLV-akgx5urbjqe",
  displayName: "Ethereum Mainnet",
  blockExplorer: "https://etherscan.io/",
  ticker: "ETH",
  tickerName: "Ethereum",
};

export const web3auth = new Web3AuthNoModal({
  clientId,
  chainConfig,
  web3AuthNetwork:
    process.env.NEXT_PUBLIC_NODE_ENV === "development"
      ? "sapphire_devnet"
      : "sapphire_mainnet",
});

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    uxMode: "redirect",
    redirectUrl: env.NEXT_PUBLIC_BASE_URL + "/login/callback",
  },
  privateKeyProvider: privateKeyProvider as any,
});

web3auth.configureAdapter(openloginAdapter);

export const useAuth = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { data: session, update } = useSession();
  const setShowLoaderOverlay = useSetRecoilState(showLoaderOverlayAtom);
  const [refreshBalanceCount] = useRecoilState(refreshBalanceCountAtom);

  //web3modal
  const { open } = useWeb3Modal();
  const { data: event } = useWeb3ModalEvents();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useWeb3ModalAccount();
  const [isInitialized, setIsInitialized] = useState(false);

  const apiLeaveUser = api.user.leave.useMutation({
    onSuccess: async () => {
      try {
        await web3auth.logout();
      } catch (e) {}

      try {
        await disconnect();
      } catch (e) {}

      void signOut();
      router.replace("/");
      setShowLoaderOverlay(false);
    },
  });

  useEffect(() => {
    if (session === undefined) {
      return;
    }

    if (session === null) {
      void logout();
      return;
    }

    // console.log(session);

    const isExpired = () => {
      const timeRemain = Date.parse(session.expires) - Date.now();

      if (timeRemain < 0) {
        Modal.confirm({
          title: "Session Expired",
          content: "Please Login",
          onOk() {
            void logout();
          },
          onCancel() {
            void logout();
          },
          cancelButtonProps: {
            style: {
              display: "none",
            },
          },
        });

        return true;
      }

      return false;
    };

    if (isExpired()) {
      return;
    }

    const interval = setInterval(() => {
      if (isExpired()) {
        clearInterval(interval);
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [session]);

  useEffect(() => {
    const lastEvent = localStorage.getItem("event");
    if (event.event === "CONNECT_SUCCESS" && lastEvent !== event.event) {
      setShowLoaderOverlay(true);
    }

    localStorage.setItem("event", event.event);
  }, [event.event]);

  useEffect(() => {
    if (session === undefined) {
      return;
    }

    // 유저정보가 없는 상태에서 지갑연결이 될 경우 signIn 진행
    if (!session?.user && isConnected && address && isInitialized) {
      void signIn("credentials", {
        walletAddress: address,
        loginType: LoginType.WEB3_MODAL,
      });

      return;
    }

    // 페이지 새로고침시 isConnected 값을 가져오기까지 시간이 걸려서 isIitailized 값 추가
    if (
      session?.user?.loginType === "WEB3_MODAL" &&
      !isConnected &&
      isInitialized
    ) {
      void logout("/");
    }
  }, [address, isConnected, session, isInitialized]);

  useEffect(() => {
    if (session?.user && ["/login", "/login/callback"].includes(pathname)) {
      router.replace("/home");
      return;
    }

    // if (session?.user && session?.user?.isAgreedTerms === null) {
    //   router.replace("/terms");
    //   return;
    // }

    if (session?.user && session?.user?.nickname === null) {
      router.replace("/setNickname");
      return;
    }

    // if (session?.user && session?.user?.countryId === null) {
    //   router.replace("/selectCountry");
    //   return;
    // }

    // if (session?.user && !(session?.user?.myFandomIds ?? []).length) {
    //   router.replace("/myFandomSetting");
    //   return;
    // }
  }, [session?.user, pathname]);

  useEffect(() => {
    const init = async () => {
      try {
        if (!web3auth?.provider) {
          await web3auth.init();
        }
      } catch (error) {
        console.error(error);
      }
    };

    void init();

    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const web3authLogin = async (loginProvider: string) => {
    await logout();

    try {
      await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const web3modalLogin = async () => {
    void open();
  };

  const logout = async (redirectUrl = "") => {
    localStorage.removeItem("privateKey");

    try {
      await web3auth.logout();
    } catch (e) {}

    try {
      await disconnect();
    } catch (e) {}

    if (redirectUrl) {
      void signOut();
      router.replace(redirectUrl);
    }
  };

  const leave = async () => {
    if (session?.user?.id) {
      apiLeaveUser.mutate({ id: session?.user?.id });
    }
  };

  return { web3authLogin, web3modalLogin, logout, leave };
};
