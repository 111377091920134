import { type User } from "@prisma/client";
import { type IProvider } from "@web3auth/base";
import { atom } from "recoil";
import Decimal from "decimal.js";

export const userAtom = atom<User>({
  key: "userAtom",
  default: undefined,
});

export const searchTextAtom = atom<string>({
  key: "searchTextAtom",
  default: "",
});

export const providerAtom = atom<IProvider | null>({
  key: "providerAtom",
  default: null,
});

export const showLoaderOverlayAtom = atom<boolean | number>({
  key: "showLoaderOverlayAtom",
  default: false,
});

export const isScrolledBottomAtom = atom<boolean>({
  key: "isScrolledBottomAtom",
  default: false,
});

export const likedFeedIdsAtom = atom<number[]>({
  key: "likedFeedIdsAtom",
  default: [],
});

export const likedCommentIdsAtom = atom<number[] | undefined>({
  key: "likedCommentIdsAtom",
  default: undefined,
});

export const refreshCountAtom = atom<number>({
  key: "refreshCountAtom",
  default: 0,
});

export const refreshBalanceCountAtom = atom<number>({
  key: "refreshBalanceCountAtom",
  default: 0,
});

export const refreshSessionCountAtom = atom<number>({
  key: "refreshSessionCountAtom",
  default: 0,
});

export const pointBalanceAtom = atom<Decimal | undefined | null>({
  key: "pointBalanceAtom",
  default: undefined,
});

export const lastDatasAtom = atom<any[]>({
  key: "lastDatasAtom",
  default: [],
});

export const lastDataTypeAtom = atom<string>({
  key: "lastDataTypeAtom",
  default: "",
});
